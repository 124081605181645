import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShortMenuAction from '../../components/_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import ShortMenuList from '../../components/_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuModale from '../../components/_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import { Link, UnlinkIcon } from 'lucide-react';
import { UnlinkKeywordsModal } from './modals/UnlinkKeywordsModal';
import { LinkBrandModal } from './modals/LinkBrandModal';

export default function StopGoBrandsActionsCell() {
  const { t } = useTranslation();
  const [unlinkOpened, setUnlinkOpened] = useState(false);
  const [linkOpened, setLinkOpened] = useState(false);

  return (
    <>
      <ShortMenuModale>
        <ShortMenuList>
          <ShortMenuAction
            icon={<Link />}
            iconMode
            text={t('Link Google Customer ID')}
            onClick={() => setLinkOpened(true)}
          />
          <ShortMenuAction
            icon={<UnlinkIcon />}
            iconMode
            text={t('Disconnect')}
            onClick={() => setUnlinkOpened(true)}
          />
        </ShortMenuList>
      </ ShortMenuModale>
      {unlinkOpened && (
        <UnlinkKeywordsModal
          onClose={() => setUnlinkOpened(false)}
        />
      )}
      {linkOpened && (
        <LinkBrandModal
          onClose={() => setLinkOpened(false)}
        />
      )}
    </>
  );
}
