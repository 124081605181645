import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import StopGoStore from './StopGo.store';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import MagicTable from '../../components/MagicTable/MagicTable';
import SwitcherCell from '../../components/MagicTable/SwitcherCell/SwitcherCell';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StopGoBrandsActionsCell from './StopGoBrandsActionsCell';
import { AddBrandModal } from './modals/AddBrandModal';

import classes from './StopGo.module.css';

function StopGo() {
  const [addModalDisplayed, setAddModalDisplayed] = useState(false);
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [stopGoStore] = useState(
      () => new StopGoStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Stop an Go'));

    return () => {
      stopGoStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.pageHeader}>
        <button
          className={classes.add}
          onClick={() => setAddModalDisplayed(true)}
        >{t('Add brand')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          <NavFilter
            isLoading={stopGoStore.isLoadingCount}
            pageStore={stopGoStore}
            counters={stopGoStore.counters}
          >
            <CompanyAccountsFilter label='company account' name='id' />
          </NavFilter>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable store={stopGoStore}>
              <DateCell field="createdDate" />
              <SwitcherCell
                field="state"
                onClick={(item, store) => { }
                  /* store.setSelectedItem({
                    companyAccount: item,
                    action: () => store.updateAutoReminder(item),
                    type: item?.reminder?.autoReminder
                      ? 'desactivate'
                      : 'activate',
                  }) */
                }
              />
              <StopGoBrandsActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={stopGoStore.count}
          perPage={stopGoStore.perPage}
          page={stopGoStore.page}
          handleSetPage={(pageNumber) => stopGoStore.setPage(pageNumber)}
        />
      </div>
      {addModalDisplayed && (
        <AddBrandModal
          onClose={
            () => {
              setAddModalDisplayed(false);
              stopGoStore.handleFetch();
            }
          }
        />
      )}
    </>
  );
}

export default observer(StopGo);
