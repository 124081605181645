import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { SelectInput } from '../../../components/form/FormSelect';

import classes from './LinkBrandModal.module.css';
import { useState } from 'react';

type Inputs = {
  brand: string;
  googleCustomerId: string;
  loginCustomerId: string;
}

interface LinkBrandModalPros {
  onClose: () => void;
}

export function LinkBrandModal({
  onClose
}: LinkBrandModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      onClose();
    }, 1500);
  };

  return (
    <FullscreenModal
      title={t('Link to brand to a Google Customer ID')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            options={[{ value: 'test', label: 'Test' }]}
          />
          <SelectInput
            name="googleCustomerId"
            label={t('Google Customer ID (Available)')}
            control={control}
            disabled={submitting}
            options={[{ value: 'gcid1 ', label: 'GC ID 1' }]}
          />
          <SelectInput
            name="loginCustomerId"
            label={t('Login Customer ID (If Available)')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
          />
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Link accounts')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
