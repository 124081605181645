import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { SelectInput } from '../../../components/form/FormSelect';
import { FormInput } from '../../../components/form/FormInput';

import classes from './AddKeywordModal.module.css';

type Inputs = {
  brand: string;
  localization: string;
  keyword: string;
  averageCtr: string;
  averageCpc: string;
  cpcCampaignIds: string[];
  negativeList: string[];
}

interface AddKeywordModalPros {
  onClose: () => void;
}

export function AddKeywordModal({
  onClose
}: AddKeywordModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      onClose();
    }, 1500);
  };

  return (
    <FullscreenModal
      title={t('Add keyword')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            options={[{ value: 'test', label: 'Test' }]}
          />
          <SelectInput
            name="localization"
            label={t('Localization')}
            control={control}
            disabled={submitting}
            options={[{ value: 'FR-fr', label: 'FR-fr' }]}
          />
          <SelectInput
            name="keyword"
            label={t('Keyword')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
          />
          <div className={classes.twoFieldsRow}>
            <FormInput
              name="averageCtr"
              label={t('Average CTR')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormInput
              name="averageCpc"
              label={t('Average CPC')}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          <SelectInput
            name="cpcCampaignIds"
            label={t('CPC Campaign IDs')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />
          <SelectInput
            name="negativeList"
            label={t('Negative list (default account)')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Add keyword')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
