import { Controller, Control } from 'react-hook-form';
import CheckboxWithLabel from '../_ui/CheckboxWithLabel/CheckboxWithLabel';

import classes from './FormCheckbox.module.css';

interface CheckboxProps {
  name: string;
  label: string;
  className?: string;
  defaultValue?: string;
  control: Control;
  disabled?: boolean;
}

export const FormCheckbox = ({
  name, label, className, defaultValue, control, disabled
}: CheckboxProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, ref }}) => (
        <CheckboxWithLabel
          value={value}
          className={className}
          setValue={onChange}
        >
          <label className={classes.label}>{label}</label>
        </CheckboxWithLabel>
      )}
    />
  );
};
