import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShortMenuAction from '../../components/_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import ShortMenuList from '../../components/_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuModale from '../../components/_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import { Link, UnlinkIcon } from 'lucide-react';
import { RetrieveKeywordsModal } from './modals/RetrieveKeywordsModal';
import { EditKeywordModal } from './modals/EditKeywordModal';

export default function StopGoKeywordsActionsCell() {
  const { t } = useTranslation();
  const [updateOpened, setUpdateOpened] = useState(false);
  const [retrieveOpened, setRetrieveOpened] = useState(false);

  return (
    <>
      <ShortMenuModale>
        <ShortMenuList>
          <ShortMenuAction
            icon={<Link />}
            iconMode
            text={t('Update options')}
            onClick={() => setUpdateOpened(true)}
          />
          <ShortMenuAction
            icon={<UnlinkIcon />}
            iconMode
            text={t('Retrieve data')}
            onClick={() => setRetrieveOpened(true)}
          />
        </ShortMenuList>
      </ ShortMenuModale>
      {updateOpened && (
        <EditKeywordModal
          onClose={() => setUpdateOpened(false)}
        />
      )}
      {retrieveOpened && (
        <RetrieveKeywordsModal
          onClose={() => setRetrieveOpened(false)}
        />
      )}
    </>
  );
}
