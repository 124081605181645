import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormCheckbox } from '../../../components/form/FormCheckbox';
import { FormDatepicker } from '../../../components/form/FormDatepicker';

import classes from './RetrieveKeywordsModal.module.css';

type Inputs = {
  startDate: string;
  endDate: string;
  googleAds: boolean;
  searchConsole: boolean;
}

interface RetrieveKeywordsModalPros {
  onClose: () => void;
}

export function RetrieveKeywordsModal({
  onClose
}: RetrieveKeywordsModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      onClose();
    }, 1500);
  };

  return (
    <FullscreenModal
      title={t('Retrieve keywords')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.twoFieldsRow}>
            <FormDatepicker
              name="startDate"
              label={t('Start date (included)')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormDatepicker
              name="endDate"
              label={t('End date (included)')}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          <FormCheckbox
            name="googleAds"
            label={t('Google Ads')}
            control={control}
            disabled={submitting}
          />
          <FormCheckbox
            name="searchConsole"
            label={t('Search Console')}
            control={control}
            disabled={submitting}
            className={classes.checkboxField}
          />
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Retrieve')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
