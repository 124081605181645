import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

FlagCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  displayTime: PropTypes.bool
};

export default function FlagCell({ item, field, displayTime }) {
  return (
    <ReactCountryFlag
      className="emojiFlag"
      countryCode={_.get(item, field)}
    />
  );
}
