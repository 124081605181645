import GoogleConnectPanel from './GoogleConnectPanel';
import classes from './GoogleConnectItemContent.module.css';
import GoogleConnectKeywords from './GoogleConnectKeywords';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../stores';
import { useContext, useEffect, useState } from 'react';
import GoogleConnectItemStore from './GoogleConnectItem.store';

export const GoogleConnectItemContent = () => {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [googleConnectStore] = useState(
      () => new GoogleConnectItemStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect item'));

    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <GoogleConnectPanel>
          <div>
            Test
          </div>
        </GoogleConnectPanel>
        <GoogleConnectPanel managedScroll={false}>
          <GoogleConnectKeywords googleConnectStore={googleConnectStore} />
        </GoogleConnectPanel>
      </div>
    </div>
  );
};
