import { Controller, Control } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './FormDatepicker.module.css';

interface DatepickerProps {
  name: string;
  label: string;
  className?: string;
  defaultValue?: string;
  control: Control;
  disabled?: boolean;
}

export const FormDatepicker = ({
  name, label, className, defaultValue, control, disabled
}: DatepickerProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, ref }}) => (
        <div className={`${classes.container} ${className || ''}`}>
          <label
            className={classes.label}
            htmlFor={name}
          >{label}</label>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={value}
            className={classes.input}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
      )}
    />
  );
};
