import { useState, ReactNode } from 'react';

import classes from './GoogleConnectPanel.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import CollapsableSearchInput from '../../components/_ui/CollapsableSearchInput/CollapsableSearchInput';

interface Props {
  title?: string | ReactNode;
  className?: string;
  headerClassName?: string;
  openToggleClassName?: string;
  openedClassName?: string;
  openedToggleClassName?: string;
  closedClassName?: string;
  closedToggleClassName?: string;
  children?: ReactNode;
  managedScroll?: boolean;
  noBody?: boolean;
  opened?: boolean;
  forcedOpened?: boolean;
  disabled?: boolean;
  noHeight?: boolean;
  centeredContent?: boolean;
  clickOnPanel?: boolean;
  searchEnabled?: boolean;
  searchTerm?: string;
  onChangeSearch?: (searchTerm: string) => void;
  onToggleOpen?: (open: boolean) => void;
}

export default function GoogleConnectPanel({
  title,
  className = '',
  headerClassName = '',
  openToggleClassName = '',
  openedClassName = '',
  openedToggleClassName = '',
  closedClassName = '',
  closedToggleClassName = '',
  children,
  managedScroll = true,
  noBody,
  opened = true,
  forcedOpened,
  disabled,
  noHeight,
  centeredContent,
  clickOnPanel,
  searchEnabled,
  onToggleOpen,
  searchTerm,
  onChangeSearch,
}: Props) {
  const [isOpened, setIsOpened] = useState(opened);

  const handleToggleOpen = () => {
    setIsOpened(!isOpened);
    onToggleOpen && onToggleOpen(!isOpened);
  };

  const openedTest = () => {
    if (forcedOpened === true) {
      return true;
    }
    if (forcedOpened === false) {
      return false;
    }
    return isOpened;
  };

  return (
    <div
      className={`${className || ''} ${classes.container}  ${
        noBody || !isOpened ? classes.noBody : ''
      } ${disabled ? classes.disabled : ''} ${
        opened ? openedClassName : closedClassName
      } ${centeredContent ? classes.centered : ''} ${
        onToggleOpen ? classes.clickable : ''
      }`}
      onClick={clickOnPanel ? handleToggleOpen : undefined}
    >
      {title && (
        <div className={`${classes.header} ${headerClassName}`}>
          <h3>{title}</h3>
          <div className={classes.toolbar}>
            {searchEnabled &&
              searchTerm !== undefined &&
              onChangeSearch !== undefined && (
              <div className={classes.search}>
                <CollapsableSearchInput
                  searchTerm={searchTerm}
                  onChangeSearch={onChangeSearch}
                />
              </div>
            )}
            <div onClick={!clickOnPanel ? handleToggleOpen : undefined}>
              <FontAwesomeIcon
                icon={openedTest() ? faChevronDown : faChevronUp}
                className={`${classes.openToggle} ${openToggleClassName} ${
                  isOpened ? openedToggleClassName : closedToggleClassName
                }`}
              />
            </div>
          </div>
        </div>
      )}
      {!noBody &&
        isOpened &&
        (managedScroll ? (
          <div
            className={`${classes.body} ${noHeight ? classes.noHeight : ''} ${
              !title ? classes.noHeader : ''
            }`}
          >
            <DynamicScrollbar>{children}</DynamicScrollbar>
          </div>
        ) : (
          <div
            className={`${classes.body} ${noHeight ? classes.noHeight : ''} ${
              !title ? classes.noHeader : ''
            }`}
          >
            {children}
          </div>
        ))}
    </div>
  );
}
