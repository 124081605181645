import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './LinkCell.module.css'; 

LinkCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default function LinkCell({ item, field, link }) {
  const value = _.get(item, field);
  return <Link className={classes.linkCell} to={link}>{value}</Link>;
}