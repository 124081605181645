import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormDatepicker } from '../../../components/form/FormDatepicker';

import classes from './MapDataModal.module.css';
import { SelectInput } from '../../../components/form/FormSelect';

type Inputs = {
  startDate: string;
  endDate: string;
}

interface MapDataModalPros {
  onClose: () => void;
}

export function MapDataModal({
  onClose
}: MapDataModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      onClose();
    }, 1500);
  };

  return (
    <FullscreenModal
      title={t('Map data')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="impactedKeywords"
            label={t('3 impacted keywords')}
            control={control}
            disabled={submitting}
            options={[
              { value: 'kw1 ', label: 'Keyword1' },
              { value: 'kw2 ', label: 'Keyword2' },
              { value: 'kw3 ', label: 'Keyword3' },
              { value: 'kw4 ', label: 'Keyword4' }
            ]}
            isMulti
          />
          <div className={classes.twoFieldsRow}>
            <FormDatepicker
              name="startDate"
              label={t('Start date (included)')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormDatepicker
              name="endDate"
              label={t('End date (included)')}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Map')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
