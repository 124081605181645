import classes from './ShortMenuAction.module.css';
import PropTypes from 'prop-types';
import ShortMenuIcon from '../ShortMenuIcon/ShortMenuIcon';

ShortMenuAction.propTypes = {
  icon: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  testid: PropTypes.string,
  setIsOpen: PropTypes.func,
  iconMode: PropTypes.bool
};

ShortMenuAction.defaultProps = {
  isActive: true,
  testid: 'ShortMenuAction'
};

export default function ShortMenuAction({ setIsOpen, icon, text, onClick, isActive, testid, iconMode }) {
  return <div
    data-isactive={isActive}
    onClick={() => {
      onClick();
      if (setIsOpen) setIsOpen(null);
    }}
    data-testid={testid}
    className={classes.container}
  >
    <span className={classes.picto}>
      {iconMode ? icon : (
        <ShortMenuIcon icon={icon} />
      )}
    </span>
    <div className={classes.text}>{text}</div>
  </div>;
}
