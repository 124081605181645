import Select from 'react-select';
import { Controller, Control } from 'react-hook-form';

import classes from './FormSelect.module.css';

export type SelectInputOption = {
  value: string;
  label: string;
}

interface SelectInputProps {
  name: string;
  label: string;
  className?: string;
  control: Control;
  options: SelectInputOption[];
  isMulti?: boolean;
  disabled?: boolean;
}

export const SelectInput = ({
  name, label, className, control, options, isMulti, disabled
}: SelectInputProps) => {
  return (
    <Controller
      control={control}
      defaultValue={options.map(c => c.value)}
      name={name}
      render={({ field: { onChange, value, ref }}) => (
        <div className={`${classes.container} ${className || ''}`}>
          <label
            className={classes.label}
            htmlFor={name}
          >{label}</label>
          <Select
            value={options.filter(c => value.includes(c.value))}
            onChange={(value: SelectInputOption) => {
              onChange(Array.isArray(value) ? value.map(v => v.value) : value.value);
            }}
            options={options}
            isMulti={isMulti}
            disabled={disabled}
            styles={{
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                fontSize: '14px',
                borderColor: '#ccc',
                // borderRadius: '10px',
                // paddingTop: '3px',
                // paddingBottom: '3px',
                // To disable the blue border
                // See https://stackoverflow.com/questions/52614304/react-select-remove-focus-border
                boxShadow: 'none',
                textAlign: 'start'
              }),
              option: (baseStyles: any, state: any) => ({
                ...baseStyles,
                // See https://stackoverflow.com/questions/73939936/react-select-how-to-change-the-font-size-on-on-the-dropdown-menu
                fontSize: '14px',
                textAlign: 'start'
              })
            }}
          />
        </div>
      )}
    />
  );
};
