import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import HeaderImage from './alert.png';

interface PurgeDataModalProps {
  onClose: () => void;
}

export function PurgeDataModal({
  onClose
}: PurgeDataModalProps) {
  const { t } = useTranslation(),
    [disabling, setDisabling] = useState(false);

  const handleDelete = async function() {
    setDisabling(true);
    setTimeout(() => {
      // toast(t('tag deleted'));
      setDisabling(false);
      onClose();
    }, 1500);
  };

  return (
    <ConfirmModal
      headerImage={<img src={HeaderImage} alt='alert header' height={100} />}
      title={''}
      message={(
        <>
          <p>{t('You\'re about to purge data of keywords.')}</p>
          <p>{t('Are you sure ?')}</p>
        </>
      )}
      setReset={onClose}
      setSubmit={handleDelete}
      isLoading={disabling}
    />
  );
}
