import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { SelectInput } from '../../../components/form/FormSelect';
import { SelectCreatableInput } from '../../../components/form/FormSelectCreatable';
import Backend from '../../../stores/newBackend';
import { StoreContext } from '../../../stores';

import type { BrandInterface } from '../../../types/brand.interface';
import type { CustomerInterface } from '../../../types/google.interface';

import classes from './AddBrandModal.module.css';

type Inputs = {
  brand: string;
  googleCustomerId: string;
  loginCustomerId: string;
  officialDomains: { value: string, label: string }[];
}

interface AddBrandModalPros {
  onClose: () => void;
}

export function AddBrandModal({
  onClose
}: AddBrandModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
  } = useForm<Inputs>();

  const selectedCustomerID = watch('googleCustomerId');
  const [submitting, setSubmitting] = useState(false);
  const { authStore } = useContext(StoreContext);

  const [brands, setBrands] = useState<BrandInterface[]>([]);
  const [customers, setCustomers] = useState<CustomerInterface[]>([]);

  useEffect(() => {
    setBrands([]);
    setCustomers([]);

    const run = async () => {
      const brandsPromise = Backend.loadBrand(authStore.token);
      const customersPromise = Backend.loadGoogleCustomerAvailable(authStore.token);

      const [brands, customers] = await Promise.all([brandsPromise, customersPromise]);
      setBrands(brands);
      setCustomers(customers);
    };

    run();
  }, [authStore.token]);

  useEffect(() => {
    const { unsubscribe } = watch((value, { name, type }) => {
      console.log({ value, name, type });
      if (name !== 'googleCustomerId') {
        return;
      }

      const selectedCustomerID = value.googleCustomerId;
      const selectedCustomer = customers.find((c) => c.ID === selectedCustomerID);

      const loginCustomerOptions = selectedCustomer && selectedCustomer.loginCustomer
        ? [
          {
            value: selectedCustomer.loginCustomer.ID,
            label: `${selectedCustomer.loginCustomer.name} (${selectedCustomer.ID})`
          }
        ]
        : [];

      setValue('loginCustomerId', loginCustomerOptions.length > 0 ? loginCustomerOptions[0].value : '');
    });
    return () => unsubscribe();
  }, [watch, customers, setValue]);

  const selectedCustomer = customers.find((c) => c.ID === selectedCustomerID);
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!selectedCustomer) {
      return;
    }

    setSubmitting(true);
    await Backend.googleAddCustomer(
      authStore.token,
      selectedCustomer.ID,
      selectedCustomer.fromID,
      selectedCustomer.loginCustomer ? selectedCustomer.loginCustomer.ID : undefined,
      data.brand,
      data.officialDomains.map(d => d.value),
    );
    setSubmitting(false);
    onClose();
  };

  const loginCustomerOptions = selectedCustomer && selectedCustomer.loginCustomer ? [{ value: selectedCustomer.loginCustomer.ID, label: `${selectedCustomer.loginCustomer.name} (${selectedCustomer.loginCustomer.ID})` }] : [];

  return (
    <FullscreenModal
      title={t('Link brand to a Google Customer ID')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="brand"
            label={t('Brand (Stop&Go)')}
            control={control}
            disabled={submitting}
            options={[...brands.map(b => ({ value: b.ID, label: b.label }))]}
          />
          <SelectInput
            name="googleCustomerId"
            label={t('Google Customer ID (Available)')}
            control={control}
            disabled={submitting}
            options={[...customers.map(c => ({ value: c.ID, label: `${c.name} (${c.ID})` }))]}
          />
          <SelectInput
            name="loginCustomerId"
            label={t('Login Customer ID (If Available)')}
            control={control}
            disabled={submitting}
            options={loginCustomerOptions}
          />
          <SelectCreatableInput
            name="officialDomains"
            label={t('Official domains')}
            control={control}
            disabled={submitting}
            isMulti
          />
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Save brand')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
