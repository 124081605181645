import { observer } from 'mobx-react-lite';
import GoogleConnectItemStore from './GoogleConnectItem.store';
import { useContext, useEffect } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../components/MagicTable/MagicTable';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import ScopeCell from '../../components/MagicTable/ScopeCell/ScopeCell';
import { StopGoGoogleConnectItemActionsCell } from './StopGoGoogleConnectItemActionsCell';

interface GoogleConnectKeywordsProps {
  googleConnectStore: GoogleConnectItemStore;
}

function GoogleConnectKeywords({
  googleConnectStore
}: GoogleConnectKeywordsProps) {
  const { uiStore } = useContext(StoreContext),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect'));

    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className='tableContainer'>
      <div className='tableContainerFilter'>
        <NavFilter
          isLoading={googleConnectStore.isLoadingCount}
          pageStore={googleConnectStore}
          counters={googleConnectStore.counters}
        >
          <CompanyAccountsFilter label='company account' name='id' />
        </NavFilter>
      </div>
      <div className='tableBox'>
        <div className='table'>
          <MagicTable store={googleConnectStore}>
            <DateCell field="createdDate" />
            <ScopeCell field="scopes" />
            <StopGoGoogleConnectItemActionsCell field="actions" />
          </MagicTable>
        </div>
      </div>
      <Pagination
        total={googleConnectStore.count}
        perPage={googleConnectStore.perPage}
        page={googleConnectStore.page}
        handleSetPage={(pageNumber) => googleConnectStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(GoogleConnectKeywords);
