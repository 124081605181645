import StopGoStore from './StopGo.store';
import { Trash2, ChartNoAxesCombined } from 'lucide-react';

import classes from './StopGoGoogleConnectItemActionsCell.module.css';

interface Props {
  readonly store?: StopGoStore
  readonly item?: any
  readonly field?: string
}

export function StopGoGoogleConnectItemActionsCell({ item, store } : Props) {
  return (
    <>
      <button className="minimal">
        <ChartNoAxesCombined className={classes.actionIcon} />
      </button>
      <button className="minimal">
        <Trash2 className={classes.actionIcon} />
      </button>
    </>
  );
}
