import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShortMenuAction from '../../components/_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import ShortMenuList from '../../components/_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuModale from '../../components/_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import StopGoStore from './StopGo.store';
import { Link, Trash2, MailOpen, ChartNoAxesCombined } from 'lucide-react';
import { PurgeDataModal } from './modals/PurgeDataModal';
import { RetrieveDataModal } from './modals/RetrieveDataModal';
import { MapDataModal } from './modals/MapDataModal';

interface Props {
  readonly store?: StopGoStore
  readonly item?: any
  readonly field?: string
}

export default function StopGoGoogleConnectActionsCell({ item, store } : Props) {
  const { t } = useTranslation();
  const [mapOpened, setMapOpened] = useState(false);
  const [retrieveOpened, setRetrieveOpened] = useState(false);
  const [purgeOpened, setPurgeOpened] = useState(false);

  return (
    <>
      <ShortMenuModale>
        <ShortMenuList>
          <ShortMenuAction 
            icon={<MailOpen />}
            iconMode
            text={t('Renew invitation')}
            onClick={() => {}}
          />
          <ShortMenuAction 
            icon={<Link />}
            iconMode
            text={t('Map datas (3 keywords)')}
            onClick={() => setMapOpened(true)}
          />
          <ShortMenuAction 
            icon={<ChartNoAxesCombined />}
            iconMode
            text={t('Get datas (124 keywords)')}
            onClick={() => setRetrieveOpened(true)}
          />
          <ShortMenuAction 
            icon={<Trash2 />}
            iconMode
            text={t('Purge datas (124 keywords)')}
            onClick={() => setPurgeOpened(true)}
          />
        </ShortMenuList>
      </ ShortMenuModale>
      {mapOpened && (
        <MapDataModal
          onClose={() => setMapOpened(false)}
        />
      )}
      {retrieveOpened && (
        <RetrieveDataModal
          onClose={() => setRetrieveOpened(false)}
        />
      )}
      {purgeOpened && (
        <PurgeDataModal
          onClose={() => setPurgeOpened(false)}
        />
      )}
    </>
  );
}
