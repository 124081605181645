import _ from 'lodash';
import PropTypes from 'prop-types';

import GoogleAnalyticsEnabled from './google-analytics.png';
import GoogleAnalyticsDisabled from './google-analytics-gris.png';
import GoogleSearchEnabled from './google-search-console.png';
import GoogleSearchDisabled from './google-search-console-gris.png';
import GoogleAdsEnabled from './logo-google-ads.png';
import GoogleAdsDisabled from './logo-google-ads-gris.png';

import classes from './ScopeCell.module.css';

ScopeCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  displayTime: PropTypes.bool
};

const POSSIBLE_SCOPES = ['google-analytics', 'search-console', 'google-ads',];
const IMAGES = {
  'google-analytics': {
    enabled: GoogleAnalyticsEnabled,
    disabled: GoogleAnalyticsDisabled
  },
  'google-ads': {
    enabled: GoogleSearchEnabled,
    disabled: GoogleSearchDisabled
  },
  'search-console': {
    enabled: GoogleAdsEnabled,
    disabled: GoogleAdsDisabled
  }
};

export default function ScopeCell({ item, field }) {
  const value = _.get(item, field);
  return (
    <>
      {POSSIBLE_SCOPES.map(possibleScope => value.includes(possibleScope) ? (
        <img src={IMAGES[possibleScope].enabled} alt='scope enable' className={classes.scopeIcon} />
      ) : (
        <img src={IMAGES[possibleScope].disabled} alt='scope disable' className={classes.scopeIcon} />
      ))}
    </>
  );
}
