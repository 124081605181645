import { observer } from 'mobx-react-lite';
import GoogleConnectStore from './GoogleConnect.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../components/MagicTable/MagicTable';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StopGoGoogleConnectActionsCell from './StopGoGoogleConnectActionsCell';
import ScopeCell from '../../components/MagicTable/ScopeCell/ScopeCell';
import LinkCell from '../../components/MagicTable/LinkCell/LinkCell';

function GoogleConnect() {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [googleConnectStore] = useState(
      () => new GoogleConnectStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect'));

    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className='tableContainer'>
      <div className='tableContainerFilter'>
        <NavFilter
          isLoading={googleConnectStore.isLoadingCount}
          pageStore={googleConnectStore}
          counters={googleConnectStore.counters}
        >
          <CompanyAccountsFilter label='company account' name='id' />
        </NavFilter>
      </div>
      <div className='tableBox'>
        <div className='table'>
          <MagicTable store={googleConnectStore}>
            <DateCell field="createdDate" />
            <ScopeCell field="scopes" />
            <LinkCell field="keywords" link="/google-connect/test" />
            <StopGoGoogleConnectActionsCell field="actions" />
          </MagicTable>
        </div>
      </div>
      <Pagination
        total={googleConnectStore.count}
        perPage={googleConnectStore.perPage}
        page={googleConnectStore.page}
        handleSetPage={(pageNumber) => googleConnectStore.setPage(pageNumber)}
      />
    </div>
  );
}

export default observer(GoogleConnect);
